import { template as template_dcd1226e10d74cc7b5737d7a4bd0bad9 } from "@ember/template-compiler";
const FKLabel = template_dcd1226e10d74cc7b5737d7a4bd0bad9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
