import { template as template_fa08e1f905874b1ab426e61179d3c424 } from "@ember/template-compiler";
import { i18n } from "discourse-i18n";
const GeneratedInviteLink = template_fa08e1f905874b1ab426e61179d3c424(`
  <div>
    <p>{{i18n "user.invited.link_generated"}}</p>
    <p>
      <input
        value={{@link}}
        class="invite-link-input"
        type="text"
        autofocus="autofocus"
      />
    </p>
    {{#if @email}}
      <p>{{i18n "user.invited.valid_for" email=@email}}</p>
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GeneratedInviteLink;
