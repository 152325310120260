import { template as template_0c81790fb1094e0c92a0ccdf339e3bf9 } from "@ember/template-compiler";
const SidebarSectionMessage = template_0c81790fb1094e0c92a0ccdf339e3bf9(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
