import { template as template_ad01b62bc78e4847a13f41800d1acfbf } from "@ember/template-compiler";
const WelcomeHeader = template_ad01b62bc78e4847a13f41800d1acfbf(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
